.main-header-container {
    width: 100%;
    min-width: 1024px;
    height: 72px;
    background-color: #096da9;
    color: #ffffff;
    margin: auto;
    position: absolute;
    top: 0px;
}

.main-header-container > .main-header {
    max-width: 1280px;
    min-width: 1024px;
    height: 100%;
    margin: auto;
    padding-left: 32px;
    padding-right: 32px;
}

.main-header-container > .main-header > .header-logo {
    display: inline-block;
    margin-top: 16px;
    width: 225px;
    height: 40px;
    background-image: url('../img/dm-header-logo.svg');
    background-size: cover;
}

.main-header-container > .main-header > .header-links-container {
    float: right;
    margin-top: 16px;
    text-align: right;
    font-size: 14px;
    line-height: 20px;
}

.main-header-container > .main-header > .header-links-container .header-link {
    color: #fff;
    margin: 0 3px;
}
    .main-header-container > .main-header > .header-links-container .header-link:first-child {
        margin-left: 0px;
    }
    .main-header-container > .main-header > .header-links-container .header-link:last-child {
        margin-right: 0px;
    }

.custom_fc_frame {
    right: 150px !important;
    bottom: 0px !important;
    z-index: 1000 !important;
}
